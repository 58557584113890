import { Box } from "@material-ui/core";
import React from "react";
import Header from "../VD Exchange/TopBar";
import Footer from "../VD Exchange/Footer";
import { useLocation } from "react-router-dom";
import Banner from "../VD Exchange/Banner";
import TopBar from "../VD Exchange/TopBar";
import Banefit from "../VD Exchange/Benefit";
import ContinuousDelivery from "../VD Exchange/ContinuousDelivery";
import PrivateBlockchain from "../VD Exchange/PrivateBlockchain";
const NavbarLayout = ({ children }) => {
  const { pathname } = useLocation();
  return (
    <Box>
      {pathname === "/" ? (
        <>
          <TopBar />
          <Banner />
          <ContinuousDelivery />
          <Box my={4}>
            <Banefit />
          </Box>
          <PrivateBlockchain />
        </>
      ) : (
        <>
          <Header />
          <Box>{children}</Box>
        </>
      )}
      <Footer />
    </Box>
  );
};

export default NavbarLayout;
