// import React from "react";
// // import TopBar from "../../../../layouts/HomeLayout/TopBar.js";
// // import Footer from "../../../../layouts/HomeLayout/Footer.js";
// import { Button, colors } from "@material-ui/core";
// import { GetApp } from "@material-ui/icons";
// import {
//   makeStyles,
//   Box,
//   Container,
//   Grid,
//   Typography,
// } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: "#F9F9F9",
//     height:"380px",
//     "& .maincontentBox": {
//       display: "flex",
//       flexDirection: "column",
//       justifyContent: "center",
//       alignItems: "center",
//     },
//   },
//   BlockchainTextBox: {
//     fontFamily: "SF Pro Display, sans-serif",
//      textAlign:"center",
//      marginTop:"100px"
//   },
//   description:{
//     colors:"#000000",
// fontSize:"18px",
// fontFamily: "SF Pro Display, sans-serif",
// fontWeight:"500",
//   },
//   aboutUsHeading: {
//     color: "#232061", // Set the color to #232061
//   },
// }));

// // const DownloadButton = () => {
// const handleDownload = () => {
//   // Logic for downloading a file
//   const link = document.createElement("a");
//   link.href = "path_to_your_file"; // Specify the file path here
//   link.download = "filename.ext"; // Specify the file name and extension here
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

// function Banner() {
//   const classes = useStyles();
//   return (
//     <Box className={classes.root}>
//       <Box className="maincontentBox">
//         <Box className={classes.BlockchainTextBox}>
//           <Typography variant="h3" className={classes.aboutUsHeading}>
//             About us
//           </Typography>
//         </Box>
//         <Box>
//           <Typography variant="h2">Smart Sustainable Solutions</Typography>
//         </Box>
//         <Box my={0.5} >
//           <Typography className={classes.description} variant="body2">
//             Innovating liquid sensing technology to safeguard assets, protect
//             the <br />environment, and drive Saudi Arabia’s leadership in sustainable
//             solutions.
//           </Typography>
//         </Box>
//       </Box>
//     </Box>
//   );
// }

// export default Banner;


import React from "react";
import { Button, colors } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import {
  makeStyles,
  Box,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F9F9F9",
    height: "380px",
    "& .maincontentBox": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto", // Adjust height for smaller screens
      padding: theme.spacing(1), // Add padding for better spacing
    },
  },
  BlockchainTextBox: {
    fontFamily: "SF Pro Display, sans-serif",
    textAlign: "center",
    marginTop: "100px",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1), // Reduce margin for small screens
    },
  },
  description: {
    color: "gray",
    fontSize: "18px",
    fontFamily: "SF Pro Display, sans-serif",
    fontWeight: "500",
    
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Adjust font size for small screens
      textAlign:"center",
    },
  },
  aboutUsHeading: {
    color: "#232061",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px", // Adjust font size for small screens
    },
  },
  subHeading: {
    fontSize: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px", // Adjust font size for small screens
    },
  },
}));

function Banner() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className="maincontentBox">
        <Box className={classes.BlockchainTextBox}>
          <Typography variant="h3" className={classes.aboutUsHeading}>
            About us
          </Typography>
        </Box>
        <Box>
          <Typography variant="h2" className={classes.subHeading}>
            Smart Sustainable Solutions
          </Typography>
        </Box>
        <Box my={0.5}>
          <Typography className={classes.description} variant="body2">
            Innovating liquid sensing technology to safeguard assets, protect
            the <br /> environment, and drive Saudi Arabia’s leadership in
            sustainable solutions.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Banner;
