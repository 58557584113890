import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Typography,
} from "@material-ui/core";
import Slider from "../../../../images/slidergroup.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 44px 70px",
    backgroundColor: "#fff",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px",
    },
  },
  heading: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  description: {
    fontSize: "18px",
    padding: "0px 220px",
    paddingBottom: "15px",
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "SF Pro Display, sans-serif",
    fontWeight: "400",
    lineHeight: "25px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 60px", // Reduce padding on tablets
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px", // Adjust padding for mobile view
      fontSize: "16px", // Smaller text on mobile
    },
  },
  ourFounder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: "center",
    width: '100%',
    height: "", // Set the height to the full viewport height
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2), // Add padding on small screens for spacing
    },
  },
  
  sliderImage: {
    width: "100%",
    maxWidth: "800px", // Limit the maximum width for large screens
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "600px", // Limit for smaller screens
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%", // Full width on mobile
    },
  },
}));

function Metaverse() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container>
        <Box align="center" p={3} className={classes.heading}>
          <Typography variant="h2">Our Milestone So Far</Typography>
          <Typography className={classes.description}>
            Milestones achieved and ambitions to revolutionize leak detection<br /> technology worldwide
          </Typography>
        </Box>
      </Container>
      <Box align="center" className={classes.ourFounder}>
        <img className={classes.sliderImage} src={Slider} alt="slider" />
      </Box>
    </Box>
  );
}

export default Metaverse;
