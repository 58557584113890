import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
    [theme.breakpoints.up("md")]: {
      padding: "50px 53px",
    },
  },
  BlockchainTextBox: {
    padding: "20px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      textAlign: "center",
    },
  },
  ImageBox: {
    "& video": {
      width: "100%",
      height: "auto",
      borderRadius: "10px",
    },
  },
  heading: {
    fontSize: "40px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
     
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem !important",
     
    },
  },
  heading2: {
    fontSize: "40px",
    fontWeight: 600,
    marginTop: "23px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      marginTop:"5px",
     
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem !important",
      marginTop:"10px",
     
    },
  },
  bodyText: {
    fontSize: "1rem",
    lineHeight: 1.6,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
}));

function Metaverse() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid container spacing={4} alignItems="center" >
        {/* Left Side: Company Information */}
        <Grid item xs={12} md={6} style={{ order: { xs: 2, md: 1 } }} >
          <Box className={classes.BlockchainTextBox}>
            <Typography className={classes.heading}>Our Company</Typography>
            <Typography className={classes.heading2}>Information</Typography>
            <Box my={3}>
              <Typography variant="body2" className={classes.bodyText}>
                Saudi Sustainability Technologies (SST) is Saudi Arabia’s first
                Climate-Tech company specializing in monitoring and controls
                solutions. Currently delivering innovative liquid leakage
                solutions addressing regional challenges of water conservation,
                hazardous environmental management, and to protect
                mission-critical applications like data centres and other
                sensitive infrastructure. As the only company across GCC to
                localize state-of-the-art liquid sensing technology, SST is
                driving resilience and sustainability across industries. Aligned
                with Vision 2030, SST is helping Saudi Arabia become a world
                leader in sustainable solutions.
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Right Side: Responsive Video */}
        <Grid item xs={12} md={6} style={{ order: { xs: 1, md: 2 } }}>
          <Box className={classes.ImageBox}>
            <video
              src="https://res.cloudinary.com/dtztsfm99/video/upload/v1720681360/LoadingPage_bkjr11.mp4"
              autoPlay
              muted
              loop
              preload="auto"
              playsInline
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Metaverse;
