// import React from "react";
// import {
//   makeStyles,
//   Box,
//   Container,
//   Grid,
//   Typography,
//   colors,
// } from "@material-ui/core";
// import person1 from "../../../../images/person1.png";
// import person10 from "../../../../images/ourFounder.png";
// import person2 from "../../../../images/person2.png";
// import person3 from "../../../../images/person3.png";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: "black",
//     paddingLeft: "50px",
//     paddingRight: "44px",
//     paddingBottom: "60px",
//     paddingTop: "20px",
//     position: "relative",
//     zIndex: "999",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     "& figure": {
//       margin: "0px",
//     },
//   },
//   MetaverseTextBox: {
//     color: "white",
//   },
//   gridItem: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     textAlign: "center",
//     color: "white",
  
//   },
//   ImageBox: {
//     "& figure": {
//       margin: "0px",
//     },
//   },
//   grid1: {
//     color: "white",
//     order: "2",
//     [theme.breakpoints.down("xs")]: {
//       order: "1",
//     },
//   },
//   grid2: {
//     order: "2",
//     [theme.breakpoints.down("xs")]: {
//       order: "1",
//     },
//   },
//   ourDescription:{
//     fontSize:"18px",
//     textAlign:"start",
//     lineHeight:"30px",
//     color:"rgba(255, 255, 255, 0.6)"
//   }
// }));

// function CustomBlockchain() {
//   const classes = useStyles();
//   return (
//     <Box className={classes.root}>
//       <Container>
//         <Grid container spacing={1}>
//           <Grid item xs={12} md={6} className={classes.grid1}>
//             <Box className={classes.MetaverseTextBox}>
//               <Typography variant="h2" style={{ paddingTop: "50px" }}>
//                 Our Founder
//               </Typography>
//               <Typography
//                 variant="body2"
//                 style={{
//                   fontSize: "18px",
//                   color: "rgba(255, 255, 255, 0.6)",
//                   fontFamily: "SF Pro Display, sans-serif",
//                   fontWeight: "400",
//                   lineHeight: "25px",
//                 }}
//               >
//                 Meet the brain behind vision
//               </Typography>
//             </Box>
//           </Grid>

        
//           <Grid container spacing={2} className={classes.grid2}>
         
//             <Grid item xs={12} md={3} sm={6} className={classes.gridItem}>
//               <img src={person10} width="100%" height="360px" alt="Photo 1" />
             
//             </Grid>

//             <Grid item xs={12} md={9} sm={6} className={classes.gridItem}>
//               <Box ml={5}>
//                 <Typography className={classes.ourDescription}>
//                   Subject Matter Expert in liquid leak detection systems of
//                   multiple International manufacturers serving across the Middle
//                   East, North Africa, and India.  Mustafa Hashmi identified a
//                   significant gap between the technologies being offered and<br />
//                   market application requirements. Having worked closely with
//                   inventors/product owners and end users, it became clear that
//                   OEMs struggled to meet regional <br />demands due to drastic
//                   difference in environment and work conditions. This led to
//                   the creation of innovative solutions tailored to the Middle
//                   East named SST, Based in Saudi Arabia, Mustafa Hashmi is on a
//                   mission to address these gaps<br /> and position Saudi Arabia
//                   standout at global stage starting with advanced liquid leak
//                   detection technology designed for local markets.
//                 </Typography>
//               </Box>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Container>
//     </Box>
//   );
// }

// export default CustomBlockchain;

import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import person10 from "../../../../images/ourFounder.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "black",
    padding: "20px 16px 40px",
    position: "relative",
    zIndex: 999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "20px 8px 40px",
    },
  },
  MetaverseTextBox: {
    color: "white",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: "white",
  },
  ImageBox: {
    "& img": {
      borderRadius: theme.spacing(1),
      width: "100%",
      maxHeight: "360px",
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "300px",
      },
      [theme.breakpoints.down("xs")]: {
        height:"100%",
        width:"100%",
      },
    },
  },
  grid1: {
    color: "white",
    order: 1,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      order: 1,
    },
  },
  grid2: {
    order: 2,
  },
  ourDescription: {
    fontSize: "16px",
    textAlign: "justify",
    lineHeight: "28px",
    color: "rgba(255, 255, 255, 0.6)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "20px",
    },
  },
}));

function CustomBlockchain() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className={classes.grid1}>
            <Box className={classes.MetaverseTextBox}>
              <Typography variant="h2" style={{ paddingTop: "30px", fontSize: "2rem" }}>
                Our Founder
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontSize: "16px",
                  color: "rgba(255, 255, 255, 0.6)",
                  fontFamily: "SF Pro Display, sans-serif",
                  fontWeight: "400",
                  lineHeight: "22px",
                }}
              >
                Meet the brain behind vision
              </Typography>
            </Box>
          </Grid>

          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
              <Box className={classes.ImageBox}>
                <img src={person10} alt="Our Founder" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={9} className={classes.gridItem}>
              <Box ml={{ sm: 2, xs: 0 }}>
                <Typography className={classes.ourDescription}>
                  Subject Matter Expert in liquid leak detection systems of
                  multiple International manufacturers serving across the Middle
                  East, North Africa, and India. Mustafa Hashmi identified a
                  significant gap between the <br />technologies being offered and
                  market application requirements. Having worked closely with
                  inventors/product owners and end users, it became clear that
                  OEMs struggled to meet<br /> regional demands due to drastic
                  differences in environment and<br /> work conditions. This led to
                  the creation of innovative solutions tailored to the Middle
                  East named SST, Based in Saudi Arabia, Mustafa Hashmi is on a
                  mission to address these gaps and <br />position Saudi Arabia
                  standout at a global stage starting with advanced liquid leak
                  detection technology designed for local markets.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default CustomBlockchain;

